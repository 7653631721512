.dragger {
    cursor: col-resize;
    height: 100%;
    position: absolute;
    right: -4px;
    top: 0;
    width: 8px;
    z-index: 1;
}

.dragging {
    border-right: 2px dotted #3B82F6;
}