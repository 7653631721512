/* CSS Styles */
.tooltip-container {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(8px, -50%);
    display: flex;
    align-items: center;
}

.tooltip-arrow-left {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #000;
    margin-right: 0px;
}

.tooltip-content {
    padding: 4px 6px
}